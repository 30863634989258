.global-theme {
  --success: #4B840C;
  --alert: #E30613;
  --warning: #FFED00;
  --white: #ffffff;
  --black: #000000;
  --blue-dark: #1b394e;
  --blue-medium: #005e7c;
  --blue-light: #008cba;
  --orange-medium: #E76100;
  --orange-light: #EC6907;
  --green-medium: #24A501;
  --green-light: #29e778;
  --gradient-blue: linear-gradient(135deg, #77e3fb 0%, #00095b 85.42%);
  --gradient-orange: linear-gradient(180deg, #ffb400 0%, #ff7d00 100%);
  --gray-100: #f4f4f3;
  --gray-300: #dbdcde;
  --gray-600: #616161;
  --gray-700: #4d4c52;
  --gray-800: #343434;
  --gray-900: #1C1C1E;
  --md: [object Object];
  --lg: [object Object];
  --2xl: [object Object];
  --font-families-inter: Inter;
  --line-heights-0: 11px;
  --line-heights-1: 20px;
  --line-heights-2: 22px;
  --line-heights-3: 24px;
  --line-heights-4: 26px;
  --line-heights-5: 28px;
  --line-heights-6: 32px;
  --line-heights-7: 40px;
  --line-heights-8: 48px;
  --line-heights-9: 64px;
  --font-weights-bold: Bold;
  --font-weights-normal: Regular;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-xl: 16px;
  --font-size-2xl: 18px;
  --font-size-3xl: 20px;
  --font-size-4xl: 24px;
  --font-size-5xl: 32px;
  --font-size-6xl: 40px;
  --font-size-7xl: 56px;
  --text-decoration-none: none;
  --text-decoration-underline: underline;
  --spacing-0: 0px;
  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-3: 4px;
  --spacing-4: 8px;
  --spacing-5: 12px;
  --spacing-6: 16px;
  --spacing-7: 24px;
  --spacing-8: 32px;
  --spacing-9: 40px;
  --spacing-10: 48px;
  --spacing-11: 56px;
  --spacing-12: 64px;
  --spacing-13: 72px;
  --spacing-14: 80px;
  --spacing-15: 88px;
  --spacing-16: 96px;
  --spacing-17: 120px;
  --border-radius-4: 4px;
  --border-radius-6: 6px;
  --border-radius-full: 9999px;
  --border-radius-none: 0px;
  --border-width-2: 2px;
  --border-width-default: 1px;
  --opacity-40: 0.4;
  --opacity-56: 0.56;
  --opacity-88: 0.88;
  --7xl: [object Object];
  --6xl: [object Object];
  --5xl-bold: [object Object];
  --5xl-normal: [object Object];
  --4xl: [object Object];
  --3xl-bold: [object Object];
  --3xl-normal: [object Object];
  --xxl: [object Object];
  --xl: [object Object];
  --base-normal: [object Object];
  --base-bold: [object Object];
  --sm-normal: [object Object];
  --sm-bold: [object Object];
  --xs-normal: [object Object];
  --xs-bold: [object Object];
}