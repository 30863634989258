@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../output/global.css";

@layer base {
  #__next {
    @apply text-blue-dark bg-white;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold text-blue-dark;
  }

  h1 {
    @apply text-6xl;
  }

  h2 {
    @apply text-5xl;
  }

  h3 {
    @apply text-4xl;
  }

  h4 {
    @apply text-3xl;
  }

  h5 {
    @apply text-2xl;
  }

  h6 {
    @apply text-xl;
  }

  b,
  strong {
    @apply font-bold;
  }
}

@layer utilities {
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  .text-shadow-md {
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  }
  .text-shadow-lg {
    text-shadow: 15px 15px 30px rgba(0, 0, 0, 0.3);
  }
  .text-shadow-none {
    text-shadow: none;
  }
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.notify {
  animation: ring 1.5s ease;
}

@keyframes ring {
  0% {
    transform: rotate(35deg);
  }

  12.5% {
    transform: rotate(-30deg);
  }

  25% {
    transform: rotate(25deg);
  }

  37.5% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(15deg);
  }

  62.5% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

[id] {
  scroll-margin-top: 190px;
}

.grecaptcha-badge {
  visibility: hidden;
}
